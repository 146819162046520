<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="content-wrapper">
          <h2 class="text-center title d-block">COPYRIGHT POLICY</h2>
          <div class="content-box text-justify">
            <div class="desp">
              <h5><strong>Notification of Copyright Infringement</strong></h5>
              <p>
                Trepr, Ltd. ("Trepr") respects the intellectual property rights of others and
                expects its users to do the same.
              </p>

              <p>
                It is Trepr’s policy, in appropriate circumstances and at its discretion, to disable
                and/or terminate the account or access of users who repeatedly infringe or are
                repeatedly charged with infringing the patents or copyrights or other intellectual
                property rights of others.
              </p>

              <p>
                In accordance with the Copyright, Designs and Patents Act 1988, the text of which
                may be found on the Copyright Service UK website at
                https://www.copyrightservice.co.uk/, Trepr will respond expeditiously to claims of
                copyright infringement committed using the Trepr website and mobile application (the
                "Site and Application") that are reported to Trepr’s Designated Copyright Agent,
                identified in the sample notice below.
              </p>

              <p>
                If you are a copyright owner, or are authorized to act on behalf of one, or
                authorized to act under any exclusive right under copyright, please report alleged
                copyright infringements taking place on or through the Site and Application by
                completing the following Electronic Commerce Directive (EU) Notice of Alleged
                Infringement and delivering it to Trepr’s Designated Copyright Agent. Upon receipt
                of the Notice as described below, Trepr will take whatever action, in its sole
                discretion, it deems appropriate, including removal of the challenged material from
                the Site and Application.
              </p>

              <p>EU Notice of Alleged Infringement ("Notice")</p>

              <p>
                Identify the copyrighted work that you claim has been infringed, or - if multiple
                copyrighted works are covered by this Notice - you will provide a comprehensive list
                of the copyrighted works that you claim have been infringed.
              </p>

              <p>
                Identify the material that you claim is infringing (or to be the subject of
                infringing activity) and that is to be removed or access to which is to be disabled,
                and information reasonably sufficient to permit us to locate the material, including
                at a minimum, if applicable, the URL of the link shown on the Site and Application
                where such material may be found.
              </p>

              <p>
                Provide your mailing address, telephone number, and, if available, email address.
              </p>

              <p>Include both of the following statements in the body of the Notice:</p>

              <p>
                "I hereby state that I have a good faith belief that the disputed use of the
                copyrighted material is not authorized by the copyright owner, its agent, or the law
                (e.g., as a fair use)."
              </p>

              <p>
                "I hereby state that the information in this Notice is accurate and, under penalty
                of perjury, that I am the owner, or authorized to act on behalf of the owner, of the
                copyright or of an exclusive right under the copyright that is allegedly infringed."
              </p>

              <p>Provide your full legal name and your electronic or physical signature.</p>

              <p>
                Deliver this Notice, with all items completed, to Trepr’s Designated Copyright
                Agent:
              </p>

              <address>
                Copyright Agent<br />

                c/o Trepr, Ltd.<br />

                Northern Ireland,<br />

                United Kingdom<br />

                copyright@Trepr.com
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
  created() {
    document.title = 'Trepr - Copyright | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', 'The Copyright Policy applies to anyone using  Trepr websites.');
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr, Trepr Copyright,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>

<style scoped></style>
